// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-floor-plans-dachboden-js": () => import("/opt/build/repo/src/pages/floor-plans/dachboden.js" /* webpackChunkName: "component---src-pages-floor-plans-dachboden-js" */),
  "component---src-pages-floor-plans-erdgeschoss-js": () => import("/opt/build/repo/src/pages/floor-plans/erdgeschoss.js" /* webpackChunkName: "component---src-pages-floor-plans-erdgeschoss-js" */),
  "component---src-pages-floor-plans-index-js": () => import("/opt/build/repo/src/pages/floor-plans/index.js" /* webpackChunkName: "component---src-pages-floor-plans-index-js" */),
  "component---src-pages-floor-plans-keller-js": () => import("/opt/build/repo/src/pages/floor-plans/keller.js" /* webpackChunkName: "component---src-pages-floor-plans-keller-js" */),
  "component---src-pages-floor-plans-obergeschoss-js": () => import("/opt/build/repo/src/pages/floor-plans/obergeschoss.js" /* webpackChunkName: "component---src-pages-floor-plans-obergeschoss-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tour-01-kueche-js": () => import("/opt/build/repo/src/pages/tour/01-kueche.js" /* webpackChunkName: "component---src-pages-tour-01-kueche-js" */),
  "component---src-pages-tour-02-wohnzimmer-js": () => import("/opt/build/repo/src/pages/tour/02-wohnzimmer.js" /* webpackChunkName: "component---src-pages-tour-02-wohnzimmer-js" */),
  "component---src-pages-tour-03-galerie-js": () => import("/opt/build/repo/src/pages/tour/03-galerie.js" /* webpackChunkName: "component---src-pages-tour-03-galerie-js" */),
  "component---src-pages-tour-04-masterbedroom-js": () => import("/opt/build/repo/src/pages/tour/04-masterbedroom.js" /* webpackChunkName: "component---src-pages-tour-04-masterbedroom-js" */),
  "component---src-pages-tour-05-balkon-js": () => import("/opt/build/repo/src/pages/tour/05-balkon.js" /* webpackChunkName: "component---src-pages-tour-05-balkon-js" */),
  "component---src-pages-tour-06-garage-js": () => import("/opt/build/repo/src/pages/tour/06-garage.js" /* webpackChunkName: "component---src-pages-tour-06-garage-js" */),
  "component---src-pages-tour-07-stadl-js": () => import("/opt/build/repo/src/pages/tour/07-stadl.js" /* webpackChunkName: "component---src-pages-tour-07-stadl-js" */),
  "component---src-pages-tour-08-garten-js": () => import("/opt/build/repo/src/pages/tour/08-garten.js" /* webpackChunkName: "component---src-pages-tour-08-garten-js" */),
  "component---src-pages-tour-09-altlengbach-js": () => import("/opt/build/repo/src/pages/tour/09-altlengbach.js" /* webpackChunkName: "component---src-pages-tour-09-altlengbach-js" */),
  "component---src-pages-tour-10-summary-js": () => import("/opt/build/repo/src/pages/tour/10-summary.js" /* webpackChunkName: "component---src-pages-tour-10-summary-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

